import React, { useEffect, useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import {
  GetDetails,
  GetPaymentData,
  sendClinicToMotion,
} from "Requests/Orders";
import { getAllUnits } from "Requests/Units";

import { createToast } from "Utils/toastFunc";

import { ContentPagesWrapper } from "layouts/ContentPagesWrapper/index";

import { Accordion } from "Components/Accordion/index";
import { Button } from "Components/Button/index";
import { DetailsInfo } from "Components/DetailsInfo/index";
import SelectControlled from "Components/Form/Controlled/SelectControlled";
import { ModalInformation } from "Components/Modals/ModalInformation/index";

import AcceptIcon from "Assets/Images/acceptIcon.svg";

import listBreadcrumb from "./listBreadcrumb.json";
import {
  ContainerRevision,
  FlexDetailsItems,
  ContentArticle,
  Centering,
  BorderSeparate,
  TitleUnity,
  ContainerUnity,
  ContainerUnityForm,
  TitleFinalValue,
  ContainerButtonRevision,
} from "./style";

const Schema = Yup.object().shape({
  unity: Yup.string().required("Obrigatório"),
});

export const RevisionBudget = () => {
  const { order_id } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [open, setOpen] = useState({
    paymentPatient: true,
    geral: true,
    payment: true,
    items: true,
  });
  const [units, setUnits] = useState([]);
  const history = useHistory();

  const currentUnityId = useMemo(() => {
    if (order?.unit_id) return String(order.unit_id);

    if (Array.isArray(order?.cart) && order?.cart[0].responsible_unit) {
      return String(order?.cart[0].responsible_unit);
    }

    return "";
  }, [order]);

  const initialValues = {
    unity: currentUnityId,
  };

  const handleOpen = (target) => {
    setOpen({ ...open, [target]: !open[target] });
  };

  async function getOrder() {
    const { data } = await GetDetails(order_id);
    const paymentResponse = await GetPaymentData(order_id);
    setOrder(data);
    setPaymentDetails(paymentResponse.data);
  }

  useEffect(() => {
    getOrder();
    fetchUnits();
  }, []);

  async function fetchUnits() {
    try {
      const { data } = await getAllUnits("clinica");
      const newArray = data.data.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setUnits([
        {
          id: "",
          name: "Selecione uma unidade",
        },
        ...newArray,
      ]);
    } catch (error) {
      createToast("error", "Ocorreu um erro ao buscar as unidades!");
    }
  }

  async function handleSubmitModal(values) {
    try {
      setIsLoading(true);
      await sendClinicToMotion({
        id: Number(order_id),
        unit_id: values.unity,
      });
      setModalVisible(true);
    } catch (error) {
      createToast("error", error?.message);
    }
    setIsLoading(false);
  }

  return (
    <ContentPagesWrapper
      titleHeader={`Revisão do Pedido Nº ${order?.id}`}
      listBreadcrumb={listBreadcrumb}
    >
      <ModalInformation
        modalVisible={modalVisible}
        modalDismiss={() => setModalVisible(false)}
        title="Pedido enviado!"
        icon={AcceptIcon}
        message="Pronto, agora é só pedir para o paciente aguardar ser chamado pela enfermeira!"
        messageButton="VOLTAR PARA TODOS OS PEDIDOS"
        onClick={() => {
          history.push(`/pedidos`);
        }}
      />
      <Centering>
        <ContainerRevision>
          <ContentArticle open={open?.paymentPatient}>
            <Accordion
              title="Dados do Paciente"
              open={open?.paymentPatient}
              handleOpen={() => handleOpen("paymentPatient")}
            >
              <DetailsInfo
                label="Nome:"
                content={order?.payer?.name || "Não informado"}
              />
              <DetailsInfo
                label="Data de Nascimento:"
                content={order?.payer?.birthdate || "Não informado"}
              />
              <DetailsInfo
                label="CPF:"
                content={order?.payer?.cpf || "Não informado"}
              />
              <DetailsInfo
                label="Telefone:"
                content={
                  order?.selected_contacts?.phone ||
                  order?.payer?.phone ||
                  "Não informado"
                }
              />
              <DetailsInfo
                label="E-mail:"
                content={
                  order?.selected_contacts?.contact ||
                  order?.payer?.email ||
                  "Não informado"
                }
              />
              <DetailsInfo
                label="ID:"
                content={order?.payer?.id || "Não informado"}
              />
            </Accordion>
          </ContentArticle>

          <ContentArticle open={open?.geral}>
            <Accordion
              title="Dados Gerais"
              open={open?.geral}
              handleOpen={() => handleOpen("geral")}
            >
              <DetailsInfo
                label="Nº do pedido:"
                content={order?.id || "Não informado"}
              />
              <DetailsInfo
                label="Nº do pedido de origem:"
                content={order?.id_source || "Não informado"}
              />
              <DetailsInfo
                label="Data do pedido:"
                content={order?.date || "Não informado"}
              />
              <DetailsInfo
                label="Data de integração Motion:"
                content={order?.motion_integration_date || "Não informado"}
              />
              <DetailsInfo
                label="Status:"
                content={order?.status || "Não informado"}
              />
              <DetailsInfo
                label="Origem:"
                content={order?.order_source || "Não informado"}
              />
            </Accordion>
          </ContentArticle>

          <ContentArticle open={open?.payment}>
            <Accordion
              title="Dados de Pagamento"
              open={open?.payment}
              handleOpen={() => handleOpen("payment")}
            >
              <FlexDetailsItems>
                <DetailsInfo
                  label="Data de pagamento:"
                  content={
                    paymentDetails[0]?.payment_transaction_date ||
                    "Não informado"
                  }
                />
                <DetailsInfo
                  label="Cupom de desconto:"
                  content={order?.coupon_code || "Não informado"}
                />
              </FlexDetailsItems>

              <DetailsInfo
                label="Número de parcelas:"
                content={
                  paymentDetails[0]?.installments_number || "Não informado"
                }
              />
              <DetailsInfo
                label="Forma de pagamento:"
                content={paymentDetails[0]?.form_payment || "Não informado"}
              />
              <DetailsInfo
                label="NSU:"
                content={paymentDetails[0]?.nsu_number || "Não informado"}
              />
              <DetailsInfo
                label="Bandeira do cartão:"
                content={
                  paymentDetails[0]?.credit_card_banner || "Não informado"
                }
              />
            </Accordion>
          </ContentArticle>

          <ContentArticle open={open?.items}>
            <Accordion
              title="Itens do Pedido"
              open={open?.items}
              handleOpen={() => handleOpen("items")}
            >
              {order?.cart?.length > 0 &&
                order?.cart.map((item) => (
                  <FlexDetailsItems>
                    <DetailsInfo label="Nome:" content={item.product_name} />
                    <DetailsInfo label="Quantidade:" content={item.amount} />
                  </FlexDetailsItems>
                ))}
            </Accordion>
          </ContentArticle>
        </ContainerRevision>

        <BorderSeparate />

        <ContainerUnity>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            schema={Schema}
            onSubmit={(values, { validateForm }) => {
              validateForm(values).then(() => {
                if (
                  !values.unity &&
                  order?.cart &&
                  !["CONS-TELE-MED", "RET-CTM"].includes(
                    order?.cart[0]?.product_code ?? ""
                  )
                ) {
                  createToast("error", "Por favor, selecione uma unidade");
                } else {
                  handleSubmitModal(values);
                }
              });
            }}
          >
            <Form>
              <ContainerUnityForm>
                {!order?.cart[0]?.product_code.includes("CONS-TELE-MED") && (
                  <div>
                    <TitleUnity>Selecione uma Unidade</TitleUnity>
                    <Field
                      name="unity"
                      options={units}
                      value={currentUnityId}
                      component={SelectControlled}
                    />
                  </div>
                )}

                <div className="paid-value">
                  <TitleFinalValue>
                    Valor Final:{" "}
                    {order?.total ? (
                      <>
                        <span>R$</span>
                        <strong>{order?.total.substring(0, 3)}</strong>
                        <span>{order?.total.substring(3)}</span>
                      </>
                    ) : (
                      <span>Não informado</span>
                    )}
                  </TitleFinalValue>
                  <ContainerButtonRevision>
                    <Button
                      classButton="radiusButton"
                      disabled={isLoading}
                      type="submit"
                    >
                      Enviar
                    </Button>
                  </ContainerButtonRevision>
                </div>
              </ContainerUnityForm>
            </Form>
          </Formik>
        </ContainerUnity>
      </Centering>
    </ContentPagesWrapper>
  );
};
