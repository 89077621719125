import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { Link, useHistory } from "react-router-dom";

import API from "Services/Api";

import { handleSuccessLogout } from "Actions/Authentication";
import { handleToggleMenu } from "Actions/Menu";

import validatePermissions from "Utils/validatePermissions";

import ConfirmAlert from "Components/Alert/ConfirmAlert";

import SchedulingIcon from "Assets/Images/Agendamentos.svg";
import Chevron from "Assets/Images/Chevron.svg";
import DesktopLogo from "Assets/Images/colabi.png";
import HealthInsuranceIcon from "Assets/Images/Convênios.svg";
import CreditIcon from "Assets/Images/Créditos.svg";
import CouponsIcon from "Assets/Images/Cupons.svg";
import MobileLogo from "Assets/Images/Logo-Colabi-mobile.png";
import BudgetIcon from "Assets/Images/Orçamentos.svg";
import OrdersIcon from "Assets/Images/Pedidos.svg";
import PersonsIcon from "Assets/Images/Pessoas.svg";
import ProductsIcon from "Assets/Images/Produtos.svg";
import LogoutIcon from "Assets/Images/Sair.svg";
import UnitsIcon from "Assets/Images/Unidades.svg";
import UsersIcon from "Assets/Images/Usuários.svg";

const Navigation = [
  {
    path: "/orcamentos/particular",
    name: "Orçamentos",
    permissions: ["Consult Budgets", "Create Budgets"],
    icon: BudgetIcon,
    children: [
      {
        name: "Particular",
        path: "/orcamentos/particular",
        permissions: ["Create Budgets", "Update Budgets"],
      },
      {
        name: "Convênio",
        path: "/orcamentos/convenio",
        permissions: ["Create Budgets", "Update Budgets"],
      },
      {
        name: "Criar novo",
        path: "/ficha-de-atendimento/paciente",
        permissions: ["Create Budgets", "Update Budgets"],
      },
    ],
  },
  {
    path: "/pedidos",
    name: "Pedidos",
    icon: OrdersIcon,
    permissions: ["Consult Orders", "Import Orders", "Send Orders to Motion"],
    children: [
      {
        name: "Particular",
        path: "/pedidos",
        permissions: [
          "Consult Orders",
          "Import Orders",
          "Send Orders to Motion",
        ],
      },
      {
        name: "Convênio",
        path: "/pedidos-convenios",
        permissions: [
          "Consult Orders",
          "Import Orders",
          "Send Orders to Motion",
        ],
      },
      {
        name: "Exportar",
        path: "/pedidos-domiciliar",
        permissions: "Send Orders to Motion",
      },
      {
        name: "Importar",
        path: "/pedidos/importar",
        permissions: "Import Orders",
      },
      {
        name: "Integração Motion",
        path: "/pedidos-domiciliar/motion",
        permissions: "Send Orders to Motion",
      },
    ],
  },
  {
    path: "/produtos",
    name: "Produtos",
    permissions: ["Consult Products", "Create Products"],
    icon: ProductsIcon,
    children: [
      {
        name: "Ver Todos os Produtos",
        path: "/produtos",
        permissions: ["Consult Products", "Create Products"],
      },
      {
        name: "Criar Novo Produto",
        path: "/produtos/novo",
        permissions: ["Create Products", "Update Products"],
      },
    ],
  },
  {
    path: "/cupons",
    name: "Cupons",
    permissions: ["Consult Coupons"],
    icon: CouponsIcon,
  },
  {
    path: "/pessoas",
    name: "Pessoas",
    permissions: "Consult Persons",
    icon: PersonsIcon,
    children: [
      {
        name: "Clientes",
        path: "/clientes",
        permissions: "Consult Persons",
      },
      {
        name: "Clientes Duplicados",
        path: "/pessoas-duplicadas",
        permissions: "Consult Persons",
      },
    ],
  },
  {
    path: "/unidades",
    name: "Unidades",
    icon: UnitsIcon,
    permissions: ["Consult Units", "Update Units"],
    children: [
      {
        name: "Criar novo",
        path: "/unidades/0",
        permissions: ["Create Units", "Update Units"],
      },
      {
        name: "Alertas",
        path: "/alertas",
        permissions: ["Create Units", "Update Units"],
      },
      {
        name: "Regiões",
        path: "/regioes",
        permissions: ["Create Units", "Update Units"],
      },
    ],
  },
  {
    name: "Convênios",
    path: "/convenios",
    permissions: ["Create Units", "Update Units"],
    icon: HealthInsuranceIcon,
  },

  {
    path: "/agendamentos",
    name: "Agendamentos",
    icon: SchedulingIcon,
    permissions: [
      "List Appointments",
      "Show Appointments Calendars",
      "Show Appointments Historic",
      "List Date and Time for Appointments",
      "Reschedule Appointments",
      "Cancel Appointments",
      "Update Appointment",
    ],
  },
  {
    path: "/labi-creditos/minha-conta",
    name: "Labi Creditos",
    icon: CreditIcon,
    permissions: "List Wallet Credits and Movimentations",
    children: [
      {
        name: "Movimentações",
        path: "/labi-creditos",
        permissions: "List Wallet Credits and Movimentations",
      },
    ],
  },
  {
    path: "/usuarios",
    name: "Usuários",
    icon: UsersIcon,
    permissions: ["Consult Users", "Create Users"],
  },
  {
    path: "/rnds",
    name: "Logs",
    permissions: "Consult RNDS",
    children: [
      {
        name: "RNDS",
        path: "/rnds",
        permissions: "Consult RNDS",
      },
      {
        name: "Produtos",
        path: "/log-produtos",
        permissions: "Consult RNDS",
      },
    ],
  },
  {
    path: "/dashboard/orders",
    name: "Dashboard",
    permissions: "Consult RNDS",
    children: [
      {
        name: "Pedidos",
        path: "/dashboard/orders",
        permissions: "Consult RNDS",
      },
      {
        name: "RNDS",
        path: "/dashboard/laudos",
        permissions: "Consult RNDS",
      },
      {
        name: "Resultados",
        path: "/dashboard/results",
        permissions: "Consult RNDS",
      },
    ],
  },
];

function Sidebar({ location }) {
  const history = useHistory();
  const [openedSub, setOpenedSub] = useState("");
  const dispatch = useDispatch();
  const opened = useSelector((state) => state.Menu.opened);
  const user_permissions = useSelector((state) => state.User.permissions);

  useEffect(() => {
    Navigation.forEach((item) => {
      if (isChildrenPage(item.children) || isCurrentPage(item.path)) {
        if (item.children && item.children.length > 0) {
          handleOpen(item.name);
        }
      }
    });
  }, [location]);

  const _logoutAlertLayout = (_, onClose, onSubmit) => (
    <div className="custom-ui">
      <h5 className="h5 text-gray text-light">
        Você tem certeza que deseja sair da aplicação?
      </h5>
      <div className="wrapper-buttons">
        <button className="link link-danger" onClick={onClose}>
          Cancelar
        </button>
        <button
          className="button _action"
          onClick={() => {
            onSubmit();
            onClose();
          }}
        >
          Sim
        </button>
      </div>
    </div>
  );

  const handleLogout = async () => {
    await API.get("/admin/user/logout")
      .then(() => {})
      .catch((error) => {});

    handleSuccessLogout();

    history.push("/login");
  };

  function handleOpen(name) {
    setOpenedSub(name);
  }

  function isChildrenPage(children) {
    let isChildren = false;

    if (children) {
      children.map((item) => {
        if (
          location.pathname === item.path ||
          location.pathname.includes(item.path)
        ) {
          isChildren = true;
        }
      });
    }

    return isChildren;
  }

  function isCurrentPage(path) {
    return location.pathname === path || location.pathname.includes(path);
  }

  function isExactPath(path) {
    return location.pathname === path;
  }

  return (
    <nav
      className={`sidebar-nav ${opened ? "-active" : ""}`}
      style={{ overflowY: "auto" }}
    >
      <div className="desktop-navbar">
        <div className="navbar-logo-desktop">
          <img
            src={DesktopLogo}
            onClick={() => history.push("/")}
            alt="Colabi"
          />
          <img
            src={Chevron}
            onClick={() => dispatch(handleToggleMenu())}
            alt="Seta"
          />
        </div>
        <div className="main-nav">
          <div className="main-nav-content">
            <ul className="main-nav-menu">
              {Navigation.map((item) => {
                if (validatePermissions(item.permissions, user_permissions))
                  return (
                    <li
                      className={`
												menu-item
												${
                          isChildrenPage(item.children) ||
                          isCurrentPage(item.path)
                            ? "menu-item-current"
                            : ""
                        }
												${
                          item.name === openedSub ||
                          isChildrenPage(item.children)
                            ? "menu-item-opened"
                            : ""
                        }
											`}
                      key={item.path}
                      onClick={
                        item.children || isChildrenPage(item.children)
                          ? () => handleOpen(item.name)
                          : null
                      }
                    >
                      <div
                        className={`link-icon-group ${
                          isExactPath(item.path) ? "yellow-border" : ""
                        }`}
                      >
                        {item.icon && (
                          <img
                            className={
                              isExactPath(item.path)
                                ? "link-icon-current"
                                : "link-icon"
                            }
                            src={item.icon}
                            alt={item.name}
                          />
                        )}
                        <Link
                          className={`text-light ${
                            isExactPath(item.path)
                              ? "current-path text-semibold"
                              : ""
                          }`}
                          to={item.path}
                        >
                          {item.name}
                        </Link>
                      </div>

                      {item.children && item.children.length > 0 && (
                        <ul className="sub-menu-item">
                          {item.children.map((subItem) => {
                            if (
                              validatePermissions(
                                subItem.permissions,
                                user_permissions
                              )
                            )
                              return (
                                <li
                                  className={`
																		menu-item
																		${
                                      location.pathname === subItem.path ||
                                      location.pathname.includes(subItem.path)
                                        ? "text-semibold"
                                        : ""
                                    }
																		${isExactPath(subItem.path) ? "yellow-border" : ""}
																	`}
                                  key={subItem.path}
                                >
                                  <Link
                                    className={`
																				text-light
																				${location.pathname === subItem.path ? "text-semibold" : ""}
																				${isExactPath(subItem.path) ? "current-path" : ""}	
																			`}
                                    to={subItem?.path}
                                  >
                                    {subItem.name}
                                  </Link>
                                </li>
                              );
                          })}
                        </ul>
                      )}
                    </li>
                  );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="mobile-navbar">
        <div className="navbar-logo-mobile">
          <img
            src={MobileLogo}
            alt="Colabi"
            onClick={() => history.push("/")}
          />
          <img
            src={Chevron}
            onClick={() => dispatch(handleToggleMenu())}
            alt="Seta"
          />
        </div>

        <div className="main-nav">
          <div className="main-nav-content">
            <ul className="main-nav-menu">
              {Navigation.map((item) => {
                if (
                  validatePermissions(item.permissions, user_permissions) &&
                  item.name !== "Logs"
                )
                  return (
                    <li
                      className={`
												menu-item
												${
                          isChildrenPage(item.children) ||
                          isCurrentPage(item.path)
                            ? "menu-item-current"
                            : ""
                        }
												${
                          item.name === openedSub ||
                          isChildrenPage(item.children)
                            ? "menu-item-opened"
                            : ""
                        }
											`}
                      key={item.path}
                    >
                      <div
                        className={`link-icon-group mobile ${
                          isCurrentPage(item.path) ||
                          isChildrenPage(item.children)
                            ? "yellow-border"
                            : ""
                        }`}
                      >
                        <Link
                          className={`text-light ${
                            isExactPath(item.path) ? "current-path" : ""
                          }`}
                          to={item.path}
                        >
                          {item.icon && (
                            <img
                              className={
                                isExactPath(item.path)
                                  ? "link-icon-current"
                                  : "link-icon"
                              }
                              src={item.icon}
                              alt={item.name}
                            />
                          )}
                        </Link>
                      </div>
                    </li>
                  );
              })}
              <li className="menu-item" key="Sair">
                <div className="link-icon-group mobile">
                  <a
                    href="#"
                    className="text-light"
                    onClick={() => {
                      ConfirmAlert(null, _logoutAlertLayout, handleLogout);
                    }}
                  >
                    <img
                      className="link-icon default-white"
                      src={LogoutIcon}
                      alt="Sair"
                    />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`menu-right ${opened ? "_active" : ""}`}>
        <button
          onClick={() => {
            ConfirmAlert(null, _logoutAlertLayout, handleLogout);
          }}
          className="link link-topbar"
        >
          Sair
        </button>
      </div>
    </nav>
  );
}

export default withRouter(Sidebar);
