import React, { useState } from "react";

import { useDebounce } from "Hooks/useDebounce";

import { Button } from "Components/Button/index";

const CartItem = ({
  isProduct,
  product,
  label,
  value,
  openModalVoucher,
  saveProductVoucher,
  readOnly,
  newLayout,
  entry,
  lineThrough,
  amountAction,
  debounceDep,
}) => {
  const [loadingIncrement, setLoadingIncrement] = useState(false);

  const handleAddProduct = async () => {
    try {
      setLoadingIncrement(true);
      await amountAction("add", product.amount, product);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingIncrement(false);
    }
  };

  const handleRemoveProduct = async () => {
    try {
      setLoadingIncrement(true);
      await amountAction("remove", product.amount, product);
    } catch (error) {
      console.log({ error });
    } finally {
      setLoadingIncrement(false);
    }
  };

  const increment = useDebounce(handleAddProduct, 700, [debounceDep]);
  const decrement = useDebounce(handleRemoveProduct, 700, [debounceDep]);

  const handleUnitPrice = () => {
    if (product?.item_unit_price) {
      return `R$ ${(product?.amount * product?.item_unit_price)
        ?.toFixed(2)
        ?.replace(".", ",")}`;
    }

    return "Coberto";
  };

  return (
    <>
      {isProduct ? (
        <div
          key={product.product_id}
          className={`cart-item ${entry ? "cart-item-entry" : ""}`}
        >
          <p className="label">
            {product.product_code && `${product.product_code} | `}{" "}
            {product.product_name}{" "}
            {product?.voucher && (
              <span className="courtesy-tag">Produto cortesia</span>
            )}
            {!newLayout && (
              <>
                {openModalVoucher && (
                  <span className="courtesy">
                    {product.voucher ? (
                      <>
                        <button type="button" onClick={openModalVoucher}>
                          Ver cortesia/voucher
                        </button>
                        <button
                          type="button"
                          className="remove"
                          onClick={saveProductVoucher}
                        >
                          remover
                        </button>
                      </>
                    ) : (
                      <button type="button" onClick={openModalVoucher}>
                        Adicionar cortesia/voucher
                      </button>
                    )}
                  </span>
                )}

                {readOnly && product.voucher && (
                  <span className="courtesy-display text-block">
                    <span>
                      <b>Voucher: </b> {product.voucher}
                    </span>
                  </span>
                )}
              </>
            )}
          </p>
          <div className="action">
            <div className="amount space-buttons">
              {amountAction && (
                <Button
                  loading={loadingIncrement}
                  type="button"
                  onClick={decrement}
                  textButton
                >
                  -
                </Button>
              )}

              <input
                type="number"
                name="amount-item"
                id="amount-item"
                className="input _number"
                readOnly
                value={product.amount}
                defaultValue={product.amount}
              />

              {amountAction && (
                <Button
                  loading={loadingIncrement}
                  type="button"
                  onClick={increment}
                  textButton
                >
                  +
                </Button>
              )}
            </div>
            {entry && <p className="price unit">R$ {product.unitValue}</p>}
            <p className="price check-out-price">{handleUnitPrice()}</p>
          </div>
        </div>
      ) : (
        <div className="cart-item">
          <p className={`label ${lineThrough ? "lineThrough" : ""}`}>{label}</p>
          <p className={`price ${lineThrough ? "lineThrough" : ""}`}>{value}</p>
        </div>
      )}
    </>
  );
};

export default CartItem;
