import { Document } from "Dtos/IBudget";
import { BudgetItemProps } from "Pages/Budgets/ViewDetails/interface";

import { formatDateBR } from "Utils/Date";
import { MaskCPF, maskPhoneNumber } from "Utils/Masks";

import { AccordionCollapse } from "Components/AccordionCollapse";
import {
  BoxInfo,
  GridAccordionContent,
  TextContent,
  Title,
} from "Components/ViewBudgetDetails/style";

export const PatientDetail = ({ data }: { data: BudgetItemProps }) => {
  const { lead, patient } = data?.info ?? {};

  const formatDocument = (document: Document) => {
    if (document.type === "cpf") return MaskCPF(document.number);

    return document.number;
  };

  const leadBudget = {
    name: lead?.name ?? "Não informado",
    document: "Não informado",
    birhdate: "Não informado",
    email: lead?.email ?? "Não informado",
    phone: lead?.phone ?? "Não informado",
    doc_type: "Não informado",
    register_type: "Lead",
  };

  const patientBudget = {
    name: patient?.social_name ?? patient?.name,
    document:
      patient?.document && !Array.isArray(patient?.document)
        ? formatDocument(patient?.document)
        : "Não informado",
    email: patient?.email.email,
    birhdate: formatDateBR(patient?.birthdate),
    phone:
      patient?.phone && !Array.isArray(patient.phone)
        ? `(${patient?.phone.ddd}) ${maskPhoneNumber(patient?.phone.phone)}`
        : "Não informado",
    doc_type: patient?.document?.type
      ? patient?.document?.type?.toUpperCase()
      : "Não informado",
    register_type: "Completo",
  };

  const personalInfo = patient ? patientBudget : leadBudget;

  return (
    <AccordionCollapse title="Dados do Cliente" cssClass="lg-12">
      <GridAccordionContent>
        <BoxInfo colStart="1/3">
          <Title>Nome</Title>
          <TextContent>{personalInfo.name}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Tipo de cadastro</Title>
          <TextContent>{personalInfo.register_type}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Tipo de Documento</Title>
          <TextContent>{personalInfo.doc_type}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Telefone</Title>
          <TextContent>{personalInfo.phone}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Data de Nascimento</Title>
          <TextContent>{personalInfo.birhdate}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Nº do Documento</Title>
          <TextContent>{personalInfo.document}</TextContent>
        </BoxInfo>

        <BoxInfo display="flex" direction="column" gap="0">
          <Title>Email</Title>
          <TextContent>{personalInfo.email}</TextContent>
        </BoxInfo>
      </GridAccordionContent>
    </AccordionCollapse>
  );
};
