import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { PhoneInputProps } from "./interface";
import { PhoneContainer } from "./styles";

export function InputPhone({
  onChange,
  value,
  placeholder,
  label,
  id,
  name,
  error,
}: PhoneInputProps) {
  return (
    <PhoneContainer className="input-phone-container">
      {label && (
        <label htmlFor={id} className="text-gray text-semibold">
          {label}
        </label>
      )}

      <PhoneInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`custom-phone ${error ? "error" : ""}`}
        id={id}
        name={id}
      />

      {error !== "" && (
        <span
          className={`error-message ${
            !label && !error ? "error-without-label" : ""
          } `}
        >
          {error}
        </span>
      )}
    </PhoneContainer>
  );
}
