import { CartItem } from "Dtos/IBudget";
import { AttendmentCollect } from "Pages/ServiceForm/components/Cart/CartDetails/interface";

export const handleTextService = (
  items: number,
  totalOrder: string,
  attendances = 1,
  is_covenant: boolean
) => {
  const itemsWord = items > 1 ? "itens" : "item";

  if (is_covenant) {
    const pluralWord = items > 1 ? "divididos" : "dividido";

    return ` Você está orçando <strong> ${items} ${itemsWord} </strong> ${pluralWord} em <strong>${attendances} atendimento</strong> no valor total de <strong>R$ ${totalOrder}</strong>`;
  }

  if (attendances === 1) {
    return ` Você está orçando <strong> ${items} ${itemsWord} </strong> dividido em <strong>${attendances} atendimento</strong> no valor total de <strong>R$ ${totalOrder}</strong>`;
  }

  return ` Você está orçando <strong> ${items} itens </strong>
            divididos em <strong>${attendances} atendimentos</strong> no valor total de <strong>R$ ${totalOrder}</strong>`;
};

export const separateAttendments = (
  cart: CartItem[] | undefined,
  budgetType: number | undefined
) => {
  const collects: AttendmentCollect[] = [
    { type: "home_collect", cartAttendments: [], budget_type: budgetType },
    { type: "unity_collect", cartAttendments: [], budget_type: budgetType },
    { type: "both_collect", cartAttendments: [], budget_type: budgetType },
    { type: "others", cartAttendments: [], budget_type: budgetType },
  ];

  if (cart) {
    cart.forEach((product) => {
      if (product.collect_type === "home_collect") {
        collects[0].cartAttendments.push(product);
        return;
      }

      if (product.collect_type === "unity_collect") {
        collects[1].cartAttendments.push(product);
        return;
      }

      if (product.collect_type === "both") {
        collects[2].cartAttendments.push(product);
        return;
      }

      collects[3].cartAttendments.push(product);
    });
  }

  return collects.filter(
    (attendments) => attendments.cartAttendments.length >= 1
  );
};

export const separateCovenantAttendment = (
  cart: CartItem[] | undefined,
  budgetType: number | undefined,
  collect_type: "home_collect" | "unity_collect"
) => {
  const collects: AttendmentCollect[] = [
    {
      type: collect_type,
      cartAttendments: [],
      budget_type: budgetType,
    },
  ];

  if (cart) {
    cart.forEach((product) => {
      collects[0].cartAttendments.push(product);
    });
  }

  return collects.filter(
    (attendments) => attendments.cartAttendments.length >= 1
  );
};
