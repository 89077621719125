import { RegionUnits } from "Hooks/useRegions/interface";
import { UnitsForm } from "Hooks/useSelectUnits/interface";

import { UnitsSelected, UnitysParams, UnitysResponse } from "./Interface";

export const formatterUnits = (unitys: UnitysParams): UnitysResponse =>
  unitys.reduce((acc: any, value: any) => {
    acc = Object.assign(acc, value);
    return acc;
  }, {});

export const formatUnitsRequest = (
  units: UnitsForm,
  regions: RegionUnits[] | undefined
) => {
  if (units && regions) {
    const removedIncludedRegions = removeRegionNotExcluded(units, regions);

    const { excluded, included } = removedIncludedRegions;

    return {
      include: included,
      exclude: excluded,
    };
  }
};

const removeRegionNotExcluded = (units: UnitsForm, regions: RegionUnits[]) => {
  const unitsremovedFromItsRegions = removeUnitsFromItsRegion(units, regions);

  const { exclude, include } = unitsremovedFromItsRegions;

  const excluded = new Set(exclude);
  const included = new Set(include);

  if (included.has(0)) {
    regions.forEach((region) => {
      if (excluded.has(region.id)) return;

      included.delete(region.id);
    });
  }

  return {
    included: Array.from(included),
    excluded: Array.from(excluded),
  };
};

export const removeUnitsFromItsRegion = (
  units: UnitsForm,
  regions: RegionUnits[]
) => {
  const include = new Set(units.include);
  const exclude = new Set(units.exclude);

  regions.forEach((region) => {
    const isRegionIncluded = include.has(region.id);
    const isRegionExcluded = exclude.has(region.id);

    region?.units?.forEach((un) => {
      if (isRegionIncluded) {
        include.delete(un.id);
      }

      if (isRegionExcluded) {
        exclude.delete(un.id);
      }
    });
  });

  return { include: Array.from(include), exclude: Array.from(exclude) };
};

export const getInitialUnitsValue = (
  units: UnitsSelected,
  regions: RegionUnits[] | undefined
) => {
  const include: Set<number> = new Set(units?.include ?? []);
  const exclude: Set<number> = new Set(units?.exclude ?? []);

  if (regions) {
    regions.forEach((region) => {
      if (exclude.has(region.id)) {
        region?.units?.forEach((un) => {
          if (include.has(un.id)) {
            include.add(un.id);
          }
        });

        return;
      }

      if (
        (include.has(0) && !exclude.has(region.id)) ||
        include.has(region.id)
      ) {
        include.add(region.id);
      }

      region?.units?.forEach((un) => {
        if (!exclude.has(un.id) && include.has(region.id)) {
          include.add(un.id);
        }
      });
    });
  }

  return {
    include: Array.from(include),
    exclude: Array.from(exclude),
  };
};
