import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

import { Box } from "styles/globalComponents";

import { CATEGORIES } from "Constants/Categories";

import { useBudgetCartModals } from "Hooks/useBudgetCartModals";
import { useBudgetContext } from "Hooks/useBudgetContext";
import { useCart } from "Hooks/useCart";

import { Store } from "Reducers/interface";

import { Button } from "Components/Button";
import { SendEmailButton } from "Components/Form/SendEmailButton";
import { LoadingText } from "Components/LoadingText";
import { CartSearch } from "Components/ServiceForm/CartSearch";
import { Heading } from "Components/ServiceForm/Heading";

import CartDetails from "../components/Cart/CartDetails";
import { FormModal } from "../components/Modals/Form";
import { TermModal } from "../components/Modals/Term";
import { PageBody, PageContainer, PageContent } from "../styles";
import { Container } from "./style";

const ServiceFormCart = () => {
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState({ term: false, form: false });

  const { budget, resetBudgetInformation } = useBudgetContext();

  const { sendEmailBudget, handlePdfDownload } = useBudgetCartModals();

  const { getBudget } = useCart();

  const { budget_id, person_type } =
    useParams<{ budget_id: string; person_type: string }>();

  const serviceFormState = useSelector((state: Store) => state.service_form);

  const { pacient } = serviceFormState;

  const hasEmail = pacient?.emails?.length;

  const hasDocument = pacient.documents?.length;

  const hasToFillForm = person_type === "person" && hasEmail && hasDocument;

  const defaultEmail = pacient?.emails?.find((email) => {
    if (email.default) return email.email;
    return email.email;
  });

  const retriveBudget = async () => {
    try {
      if (budget_id) {
        setLoading(true);
        await getBudget(+budget_id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = (modal: "term" | "form") =>
    setModals((prev) => ({ ...prev, [modal]: false }));

  const openModal = (modal: "term" | "form") =>
    setModals((prev) => ({ ...prev, [modal]: true }));

  useEffect(() => {
    retriveBudget();
  }, []);

  useEffect(() => {
    return () => resetBudgetInformation();
  }, []);

  return (
    <PageContainer className="entry-app-content entry-budget">
      <PageContent className="content-holder page-budget">
        <Heading
          list={[
            {
              path: "/orcamentos",
              label: "Orçamento",
              current: false,
            },
            {
              path: "#",
              label: "Criar novo",
              current: true,
            },
          ]}
          title="Orçamentos"
        />

        <PageBody className="cart-order-budget">
          <Container className="card-search-information gray-background">
            {loading ? (
              <Box mt="300">
                <LoadingText text="Aguarde ! Estamos recuperando os dados do orçamento salvo." />
              </Box>
            ) : (
              <CartSearch />
            )}
          </Container>
          <CartDetails>
            {budget?.budget_type === CATEGORIES.consult && (
              <>
                <SendEmailButton
                  placeholder="Email do paciente"
                  value={defaultEmail?.email ?? ""}
                  label="Enviar por e-mail"
                  submit={(budgetEmail) =>
                    sendEmailBudget(budgetEmail, budget?.id)
                  }
                />

                <Box display="flex" direction="column" gap="16" mt="60">
                  <Button
                    onClick={() => handlePdfDownload(budget?.id)}
                    width="full"
                    outlined
                  >
                    Baixar como .PDF
                  </Button>
                  <Button
                    onClick={() =>
                      hasToFillForm ? openModal("term") : openModal("form")
                    }
                    type="button"
                    classButton="button-order button _block _xs"
                    borderRadius="30"
                  >
                    Converter em pedido
                  </Button>
                </Box>
              </>
            )}
          </CartDetails>
        </PageBody>
      </PageContent>

      <TermModal
        closeModal={closeModal}
        isOpen={modals.term}
        openModal={openModal}
      />

      <FormModal closeModal={closeModal} isOpen={modals.form} />
    </PageContainer>
  );
};

export default ServiceFormCart;
