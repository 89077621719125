import { getTitle } from "Pages/ServiceForm/helper";
import { Box } from "styles/globalComponents";

import { CATEGORIES } from "Constants/Categories";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useCart } from "Hooks/useCart";

import CartItem from "Components/ServiceForm/CartItem";

import ClincisIcon from "Assets/Images/clinics-icon.png";
import HomeIcon from "Assets/Images/home-icon.png";
import partnerIcon from "Assets/Images/partner-icon.png";

import { AttendmentTypeProps } from "./interface";
import { Container } from "./styles";

export const AttendmentItem = ({ attendment }: AttendmentTypeProps) => {
  const { budget, zipCode } = useBudgetContext();
  const { updateAmountCartButton } = useCart();

  const { is_covenant, unit } = budget ?? {};

  const { cartAttendments, type, budget_type } = attendment;

  const title = getTitle(type);

  const zipcode = zipCode.replace(/^(\d{5})(\d{3})$/, "$1-$2");

  return (
    <Container>
      <section className="budget-collect-type">
        <article>
          {budget_type === CATEGORIES.image && (
            <div>
              <img src={partnerIcon} alt="" />
            </div>
          )}

          {budget_type !== CATEGORIES.image && (
            <>
              {type === "home_collect" && (
                <div>
                  <img src={HomeIcon} alt="" />
                </div>
              )}
              {type === "unity_collect" && (
                <div>
                  <img src={ClincisIcon} alt="" />
                </div>
              )}

              {type === "both_collect" && (
                <Box display="flex">
                  <img src={HomeIcon} alt="" />
                  <img src={ClincisIcon} alt="" />
                </Box>
              )}
            </>
          )}
        </article>
        <Box display="flex" direction="column" gap="0" align="flex-start">
          <p className="cart-home-or-unit-title">
            <strong>ATENDIMENTO</strong> <br />
            {budget_type === CATEGORIES.consult && "LABI CLÍNICA"}
            {budget_type === CATEGORIES.image && "PARCEIRO"}
            {budget_type !== CATEGORIES.consult &&
              budget_type !== CATEGORIES.image &&
              !is_covenant &&
              title.toUpperCase()}
          </p>

          {is_covenant && (
            <p className="color-purple">
              {" "}
              {zipCode
                ? `EM CASA - CEP ${zipcode} `
                : `NA UNIDADE ${unit ? unit?.name.toLocaleUpperCase() : ""}`}
            </p>
          )}
        </Box>
      </section>
      <div className="cart-itens-holder new-layout-cart-itens-holder">
        {cartAttendments.map((item) => (
          // @ts-ignore
          <CartItem
            key={item?.id}
            isProduct
            product={item}
            newLayout
            amountAction={updateAmountCartButton}
            debounceDep={budget}
          />
        ))}
      </div>
    </Container>
  );
};
