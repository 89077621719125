import { Link } from "react-router-dom";

import { Box } from "styles/globalComponents";

import { useBudgetCartModals } from "Hooks/useBudgetCartModals";
import { useBudgetContext } from "Hooks/useBudgetContext";

import { CopyToClipboard } from "Utils/Copy";

import { Button } from "Components/Button";
import { SendEmailButton } from "Components/Form/SendEmailButton";
import { Modal } from "Components/Modals/Modal";

import Success from "Assets/Images/successIcon.svg";

interface SuccessModalProps {
  isOpen: boolean;
  closeModal: () => void;
  openMotionModal: () => void;
}

export const SuccessModal = ({
  isOpen,
  closeModal,
  openMotionModal,
}: SuccessModalProps) => {
  const { budget } = useBudgetContext();

  const { handlePdfDownload, sendEmailBudget, loadingPdf } =
    useBudgetCartModals();

  const covenantLink = budget?.is_covenant ? "convenio" : "particular";

  const copyBudgetLink = () => {
    const urlOrigin = window.location.origin;
    const url = `${urlOrigin}/orcamento/${budget?.id}`;
    CopyToClipboard({ message: "Copiado!", text: url });
  };

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Success}
      renderHeading={() => (
        <h2>
          {" "}
          Seu orçamento está <strong>salvo!</strong>
        </h2>
      )}
      renderDescription={() => (
        <p>
          Agora é só compartilhar o orçamento com o cliente por uma das opções
          abaixo:
        </p>
      )}
      renderActions={() => (
        <Box display="flex" direction="column">
          {" "}
          {!budget?.is_covenant && (
            <Button
              onClick={() =>
                CopyToClipboard({
                  message: "Copiado!",
                  text: budget?.site_link || "",
                })
              }
              width="full"
            >
              COPIAR LINK PAGÁVEL DO SITE
            </Button>
          )}
          <Button
            onClick={() => handlePdfDownload(budget?.id)}
            width="full"
            outlined
            loading={loadingPdf}
            disabled={loadingPdf}
          >
            Baixar como .PDF
          </Button>
          <Button width="full" outlined onClick={copyBudgetLink}>
            {" "}
            Copiar código do orçamento
          </Button>
          <SendEmailButton
            placeholder="Email do paciente"
            value={budget?.patient?.email?.email || budget?.lead?.email || ""}
            label="Enviar por e-mail"
            submit={(budgetEmail) => sendEmailBudget(budgetEmail, budget?.id)}
          />
          {budget?.is_covenant && (
            <Button width="full" outlined onClick={openMotionModal}>
              {" "}
              INTEGRAR COM O MOTION
            </Button>
          )}
          <Button width="full" textButton>
            <Link to={`/orcamentos/${covenantLink}`}>ver todos orçamentos</Link>
          </Button>
        </Box>
      )}
    />
  );
};
