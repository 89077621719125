import { BudgetMotion, BudgetMotionParams } from "Dtos/IMotion";

import { addGeneralInfoAndDocuments } from "Requests/Budget";
import { CreateImgCovenant, updateCovenantCredentials } from "Requests/Person";

import { useBudgetCartModals } from "Hooks/useBudgetCartModals";
import { useBudgetContext } from "Hooks/useBudgetContext";
import { useCart } from "Hooks/useCart";

import { handleRequestErrors } from "Utils/Errors";
import { createToast } from "Utils/toastFunc";

import CoupomModal from "Components/Modals/CoupomModal";
import CourtesyVoucherModal from "Components/Modals/CourtesyVoucherModal";
import { CovenantCredialAttach } from "Components/Modals/CovenantCredialAttach";
import DeleteBudgetCart from "Components/Modals/DeleteBudgetCart";
import { GeneralInformationModal } from "Components/Modals/GeneralInformationModal";
import MedicalRecordModal from "Components/Modals/MedicalRecordModal";
import { ModalCovenantMotion } from "Components/Modals/Motion/CovenantIntegration";
import { CovenantSendParams } from "Components/ServiceForm/ModalsClientCovenant/interface";

import { MotionModal } from "./Motion";
import { MotionSuccessModal } from "./MotionSuccess";
import { SuccessModal } from "./Success";

interface ModalsActionsProps {
  modals: {
    courtesy: boolean;
    coupon: boolean;
    delete: boolean;
    medicalAttach: boolean;
    adittionalInfo: boolean;
    credentials: boolean;
    budgetSuccess: boolean;
    motionBudget: boolean;
    motionBudgetSuccess: boolean;
    motionCovenant: boolean;
  };
  closeModal: (modal: string) => void;
  openModal: (modal: string) => void;
}

export const ModalsBudgetActions = ({
  modals,
  closeModal,
  openModal,
}: ModalsActionsProps) => {
  const {
    budget,
    documents,
    resetBudgetInformation,
    selectedCovenant,
    patientSelected,
  } = useBudgetContext();

  const {
    uploadBudgetDocument,
    addGeneralInfoBudget,
    removeBudgetDocument,
    createProductCourtesyBudget,
    handleRemoveCoupon,
    handleUpdateCoupon,
    deleteBudget,
  } = useCart();

  const { motionIntegration } = useBudgetCartModals();

  const motionBudget = async (id: number | null) => {
    if (!id && budget?.is_covenant && budget.home_collected) {
      return createToast(
        "error",
        "Seleciona uma unidade para integrar ao orçamento"
      );
    }
    if (id && budget?.id) await motionIntegration(budget.id, id);

    if (budget?.id && budget?.unit) {
      const status = await motionIntegration(budget.id, budget.unit.id);

      if ([200, 201, 202].includes(status)) openModal("motionBudgetSuccess");
    }
  };

  const confirmDelete = async () => {
    try {
      if (budget) {
        await deleteBudget(budget.id, "Orçamento deletado com sucesso!");
        resetBudgetInformation();
      }
    } catch (error) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage: "Não foi possível deletar este orçamento.  ",
      });
    }
  };

  const handleUpdateCovenant = async ({
    covenant_id,
    covenant_plan_id,
    credential_number,
  }: CovenantSendParams) => {
    const personId = patientSelected.id;
    const credentialId = selectedCovenant?.id;
    const credentials = {
      covenant_id,
      covenant_plan_id,
      credential_number,
    };

    if (credentialId) {
      await updateCovenantCredentials(personId, credentials, credentialId);
    }
  };

  const handleUploadCovenant = async (frontCard: File, backCard: File) => {
    const personId = patientSelected.id;
    const credentialId = selectedCovenant?.id;

    if (credentialId) {
      const formData = new FormData();
      formData.append("front_image", frontCard);
      formData.append("back_image", backCard);

      await CreateImgCovenant(formData, personId, credentialId);
    }
  };

  const handleUploadMedicalOrder = async (medicalOrder: File) => {
    const formData = new FormData();
    const budgetId = budget?.id;
    formData.append("order", medicalOrder);

    if (budgetId) {
      await addGeneralInfoAndDocuments(budgetId, formData);
    }
  };

  const onSubmit = async (values: BudgetMotion) => {
    try {
      const submitValues = values as BudgetMotionParams;

      const promises = [
        await handleUploadCovenant(
          submitValues.front_card,
          submitValues.back_card
        ),

        await handleUpdateCovenant({
          covenant_id: submitValues.covenant,
          covenant_plan_id: submitValues.plan,
          credential_number: submitValues.plan_card_number,
        }),

        await handleUploadMedicalOrder(submitValues.medical_order),
      ];

      await Promise.all(promises);

      openModal("motionBudget");
    } catch (error) {
      handleRequestErrors({
        reqErrors: error,
        errorMessage:
          "Não foi possível atualizar os dados do convênio deste usuário.",
      });
    }
  };

  return (
    <>
      <SuccessModal
        closeModal={() => closeModal("budgetSuccess")}
        isOpen={modals.budgetSuccess}
        openMotionModal={() => openModal("motionCovenant")}
      />

      <MotionModal
        isOpen={modals.motionBudget}
        closeModal={() => closeModal("motionBudget")}
        motionIntegration={motionBudget}
        showUnitsOptions={budget?.is_covenant && budget.home_collected}
      />

      {selectedCovenant && (
        <ModalCovenantMotion
          isOpen={modals.motionCovenant}
          closeModal={() => closeModal("motionCovenant")}
          covenantSelected={selectedCovenant}
          onSubmit={onSubmit}
        />
      )}

      <MotionSuccessModal
        closeModal={() => closeModal("motionBudgetSuccess")}
        isOpen={modals.motionBudgetSuccess}
        budgetId={budget?.id}
      />

      {/* @ts-ignore */}
      <MedicalRecordModal
        modalVisible={modals.medicalAttach}
        modalDismiss={() => closeModal("medicalAttach")}
        documents={documents}
        removeDocument={removeBudgetDocument}
        callbackFinish={uploadBudgetDocument}
        budget={budget}
      />

      <GeneralInformationModal
        modalVisible={modals.adittionalInfo}
        generalInformation={budget?.general_information || ""}
        modalDismiss={() => closeModal("adittionalInfo")}
        addInformation={addGeneralInfoBudget}
      />

      <CovenantCredialAttach
        modalVisible={modals.credentials}
        modalDismiss={() => closeModal("credentials")}
        documents={documents}
        removeDocument={removeBudgetDocument}
        callbackFinish={uploadBudgetDocument}
        budget={budget}
      />

      <CourtesyVoucherModal
        saveProductCourtesy={createProductCourtesyBudget}
        modalVisible={modals.courtesy}
        modalDismiss={() => closeModal("courtesy")}
        budget={budget?.cart}
      />

      <CoupomModal
        modalVisible={modals.coupon}
        modalDismiss={() => closeModal("coupon")}
        budget={budget}
        handleRemoveCoupon={handleRemoveCoupon}
        handleUpdateCoupon={handleUpdateCoupon}
      />

      <DeleteBudgetCart
        deleteBudget={confirmDelete}
        modalVisible={modals.delete}
        budget_id={budget?.id}
        modalDismiss={() => closeModal("delete")}
      />
    </>
  );
};
