import { BudgetMotion, BudgetMotionParams } from "Dtos/IMotion";
import { MotionModal } from "Pages/ServiceForm/components/Cart/CartActions/Modals/Motion";
import { MotionSuccessModal } from "Pages/ServiceForm/components/Cart/CartActions/Modals/MotionSuccess";
import { Box } from "styles/globalComponents";

import { addGeneralInfoAndDocuments } from "Requests/Budget";
import { CreateImgCovenant, updateCovenantCredentials } from "Requests/Person";

import { CopyToClipboard } from "Utils/Copy";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import { SendEmailButton } from "Components/Form/SendEmailButton";
import { Modal } from "Components/Modals/Modal";
import { CovenantSendParams } from "Components/ServiceForm/ModalsClientCovenant/interface";

import Success from "Assets/Images/successIcon.svg";

import { ModalCovenantMotion } from "../Motion/CovenantIntegration";
import { ModalAlertProps } from "./interface";

export const BudgetActionsModals = ({
  handlePdfDownload,
  modalOpen,
  motionIntegration,
  onCloseModal,
  onOpenModal,
  sendEmailBudget,
  cancelBudget,
  redirectToCreateNewBudget,
  budget,
  loading,
  duplicateBudgetInfo,
  redirectToBudgetList,
  loadingPdf,
}: ModalAlertProps) => {
  const { covenant_data } = budget ?? {};

  const changeBudgetStatus = async () => {
    try {
      await cancelBudget();
      onOpenModal("budgetCanceledSuccess");
    } catch (error) {
      console.log(error);
    }
  };

  const motionBudget = async (id: number | null) => {
    if (!id && budget?.is_covenant && budget.home_collected) {
      return createToast(
        "error",
        "Seleciona uma unidade para integrar ao orçamento"
      );
    }
    if (id && budget?.id) await motionIntegration(budget.id, id);

    if (budget?.id && budget?.unit)
      await motionIntegration(budget.id, budget.unit.id);
  };

  const handleUpdateCovenant = async ({
    covenant_id,
    covenant_plan_id,
    credential_number,
  }: CovenantSendParams) => {
    try {
      const personId = budget?.patient?.id;
      const credentialId = budget?.covenant_data?.credential_id;
      const credentials = {
        covenant_id,
        covenant_plan_id,
        credential_number,
      };

      if (credentialId) {
        await updateCovenantCredentials(personId, credentials, credentialId);
      }
    } catch (error) {
      throw new Error("Não foi possível atualizar dos dados do convênio");
    }
  };

  const handleUploadCovenant = async (frontCard: File, backCard: File) => {
    try {
      const personId = budget?.patient?.id;
      const credentialId = budget?.covenant_data?.credential_id;

      if (credentialId) {
        const formData = new FormData();
        formData.append("front_image", frontCard);
        formData.append("back_image", backCard);

        await CreateImgCovenant(formData, personId, credentialId);
      }
    } catch (error) {
      throw new Error(
        "Não foi possível fazer o upload dos dados da carteirinha"
      );
    }
  };

  const handleUploadMedicalOrder = async (medicalOrder: File) => {
    try {
      const formData = new FormData();
      const budgetId = budget?.id;
      formData.append("order", medicalOrder);

      if (budgetId) {
        await addGeneralInfoAndDocuments(budgetId, formData);
      }
    } catch (error) {
      throw new Error("Não foi possível fazer o upload do pedido médico");
    }
  };

  const onSubmit = async (values: BudgetMotion) => {
    try {
      const submitValues = values as BudgetMotionParams;

      const promises = [
        await handleUploadCovenant(
          submitValues.front_card,
          submitValues.back_card
        ),
        await handleUpdateCovenant({
          covenant_id: submitValues.covenant,
          covenant_plan_id: submitValues.plan,
          credential_number: submitValues.plan_card_number,
        }),
        await handleUploadMedicalOrder(submitValues.medical_order),
      ];

      await Promise.all(promises);

      onOpenModal("motionBudget");
    } catch (error: any) {
      createToast("error", error.message);
    }
  };

  return (
    <>
      <Modal
        closeOverlay
        isOpen={modalOpen.resendEmail}
        requestClose={() => onCloseModal("resendEmail")}
        modalIcon={Success}
        renderHeading={() => <h2>Reenvio de orçamento</h2>}
        renderDescription={() => (
          <p>
            Selecione como você gostaria de reenviar esse orçamento ao cliente
            utilizando uma das opções abaixo:
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            {" "}
            {!budget?.is_covenant && (
              <Button
                onClick={() =>
                  CopyToClipboard({
                    message: "Copiado!",
                    text: budget?.site_link || "",
                  })
                }
                width="full"
              >
                Copiar link pagável do site!
              </Button>
            )}
            <SendEmailButton
              placeholder="Email do paciente"
              value={budget?.email ?? ""}
              label="Enviar por e-mail"
              submit={(patientEmail) =>
                sendEmailBudget(patientEmail, budget?.id)
              }
            />
            <Button
              onClick={() => handlePdfDownload(budget?.id)}
              width="full"
              outlined
              disabled={loadingPdf}
              loading={loadingPdf}
            >
              Baixar como PDF
            </Button>
            <Button
              width="full"
              textButton
              onClick={() => onCloseModal("resendEmail")}
            >
              voltar para o orçamento
            </Button>
          </Box>
        )}
      />

      {modalOpen.motionBudget && (
        <MotionModal
          isOpen={modalOpen.motionBudget}
          closeModal={() => onCloseModal("motionBudget")}
          motionIntegration={motionBudget}
          showUnitsOptions={budget?.is_covenant && budget.home_collected}
        />
      )}

      {covenant_data && budget?.is_covenant && (
        <ModalCovenantMotion
          isOpen={modalOpen.motionCovenant}
          closeModal={() => onCloseModal("motionCovenant")}
          covenantSelected={{
            credential_number: covenant_data.covenant_plan_credential
              ? String(covenant_data.covenant_plan_credential)
              : null,
            covenant: {
              id: covenant_data.covenant_id,
              covenant: covenant_data.covenant_name,
            },
            covenant_plan: {
              id: covenant_data.covenant_plan_id,
              covenant_plans_name: covenant_data.covenant_plan_name,
              covenant_plans_code: covenant_data.covenant_plan_code,
            },
          }}
          onSubmit={onSubmit}
        />
      )}

      <MotionSuccessModal
        budgetId={budget?.id}
        closeModal={() => onCloseModal("motionBudgetSuccess")}
        isOpen={modalOpen.motionBudgetSuccess}
      />

      <Modal
        closeOverlay
        isOpen={modalOpen.cancelBudget}
        requestClose={() => onCloseModal("cancelBudget")}
        modalIcon={Success}
        renderHeading={() => <h2>Cancelar um orçamento salvo</h2>}
        renderDescription={() => (
          <p>
            <strong>Atenção:</strong> Ao cancelar este orçamento, iremos remover
            todas as menções deste orçamento para o cliente e não será mais
            possível utilizar o link de pagamento do site. Deseja prosseguir com
            o cancelamento?
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            {" "}
            <Button
              minHeight="48"
              width="full"
              onClick={changeBudgetStatus}
              loading={loading}
            >
              PROSSEGUIR COM CANCELAMENTO
            </Button>
            <Button
              minHeight="48"
              outlined
              width="full"
              onClick={() => onCloseModal("cancelBudget")}
            >
              VOLTAR PARA ORÇAMENTO
            </Button>
          </Box>
        )}
      />

      <Modal
        closeOverlay
        isOpen={modalOpen.budgetCanceledSuccess}
        requestClose={() => onCloseModal("budgetCanceledSuccess")}
        modalIcon={Success}
        renderHeading={() => <h2>Orçamento cancelado com sucesso</h2>}
        renderDescription={() => (
          <p>
            Este orçamento foi cancelado com sucesso e todas suas menções foram
            removidas da visualização para o cliente, no entanto você seguirá
            visualizando seu histórico no Colabi.
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            {" "}
            <Button minHeight="48" width="full" onClick={redirectToBudgetList}>
              VOLTAR PARA ORÇAMENTOS
            </Button>
            <Button
              minHeight="48"
              outlined
              width="full"
              onClick={redirectToCreateNewBudget}
            >
              CRIAR NOVO ORÇAMENTO
            </Button>
          </Box>
        )}
      />

      <Modal
        closeOverlay
        isOpen={modalOpen.editBudget}
        requestClose={() => onCloseModal("editBudget")}
        modalIcon={Success}
        renderHeading={() => <h2>Editar um orçamento salvo</h2>}
        renderDescription={() => (
          <p>
            A edição gerará um novo orçamento a partir deste orçamento salvo que
            você está visualizando. Deseja continuar?
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            {" "}
            <Button
              minHeight="48"
              width="full"
              onClick={duplicateBudgetInfo}
              loading={loading}
              disabled={loading}
            >
              EDITAR COMO NOVO ORÇAMENTO
            </Button>
            <Button
              minHeight="48"
              outlined
              width="full"
              disabled={loading}
              onClick={() => onCloseModal("editBudget")}
            >
              CANCELAR
            </Button>
          </Box>
        )}
      />
    </>
  );
};
