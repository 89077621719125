import React from "react";

import { Loader } from "Components/Loader";

import { ButtonProps } from "./interface";
import { ButtonContainer } from "./style";

export const Button = ({
  onClick = undefined,
  children,
  form = "",
  loading = false,
  type = "button",
  disabled = false,
  borderRadius,
  outlined = false,
  classButton = "",
  color,
  minHeight,
  padding,
  transparentHover = false,
  width,
  textButton,
}: ButtonProps) => {
  return (
    <ButtonContainer
      {...(form && { form })}
      type={type}
      onClick={onClick}
      disabled={disabled}
      borderRadius={borderRadius}
      color={color}
      minHeight={minHeight}
      transparentHover={transparentHover}
      padding={padding}
      width={width}
      className={`
      ${classButton}
      ${outlined ? "outlined" : ""}
      ${textButton ? "textButton" : ""}

      `}
    >
      {loading ? (
        <Loader
          color="white"
          size={15}
          hoverColor="#572580"
          className="loader"
        />
      ) : (
        children
      )}
    </ButtonContainer>
  );
};
