import React from "react";

import { useProductList } from "Pages/ServiceForm/SelectProducts/useProductList";
import { Box } from "styles/globalComponents";

import { useBudgetContext } from "Hooks/useBudgetContext";
import { useProductCombos } from "Hooks/useProductCombos";

import { Loading } from "Utils/Loading";

import { arrayOfServices } from "mocks";

import { LoadingText } from "Components/LoadingText";
import { Pagination } from "Components/Pagination";

import { BudgetProductsFilter } from "../Filters";
import { ProductsListResult } from "../ProductResultList";

const combosParams = {
  includeCombo: [
    "categories",
    "subcategories",
    "status",
    "services",
    "partners",
  ],
  searchBudget: true,
};

export const CartSearch = () => {
  const {
    loadingProducts,
    getProducts,
    productsList,
    fetchNewPage,
    pageSelected,
    productsPagination,
  } = useProductList();

  const { loadingCombos } = useProductCombos(combosParams);

  const loadingProductsOrCombo = loadingProducts || loadingCombos;

  const { productsCategoriesSelected, loadingBudget } = useBudgetContext();

  const searchTitle = arrayOfServices.find((service) => {
    if (productsCategoriesSelected) {
      return service.categories_included.includes(
        productsCategoriesSelected[0]
      );
    }
  });

  if (loadingBudget) {
    return (
      <div className="card-search-information">
        <LoadingText text="Aguarde ! Estamos recuperando os dados do orçamento salvo." />
      </div>
    );
  }

  return (
    <div>
      <h5 className="title-search-products-budget">
        Buscar produtos {searchTitle ? searchTitle.text : ""}
      </h5>

      <BudgetProductsFilter
        getProducts={getProducts}
        fetchNewPage={fetchNewPage}
      />

      {loadingProductsOrCombo ? (
        <Box mt="30">
          <Loading />
        </Box>
      ) : (
        <div className="cart-flex-products">
          {!productsList && !loadingProducts && (
            <p className="text-danger text-display text-center text-no-margin">
              Oops! Nenhum produto encontrado...
            </p>
          )}

          {!loadingProductsOrCombo && productsList && (
            <>
              <ProductsListResult productsList={productsList} />

              <Box mt="20">
                <Pagination
                  pageLimit={1}
                  pageNeighbours={2}
                  totalRecords={productsPagination?.total_pages}
                  currentPageSelected={pageSelected}
                  onPageChanged={(pageObject) =>
                    fetchNewPage(pageObject.currentPage)
                  }
                />
              </Box>
            </>
          )}
        </div>
      )}
    </div>
  );
};
