import styled, { css } from "styled-components";

interface ButtonStyles {
  borderRadius: string;
  color: string | undefined;
  minHeight: string;
  transparentHover: boolean;
  padding: string;
  width: string;
}

export const ButtonContainer = styled.button.attrs(
  ({ borderRadius, minHeight, padding, width, color }: ButtonStyles) => {
    let selectedWidth = width ? `${width}px` : "";

    if (width === "full") selectedWidth = "100%";
    if (width === "auto") selectedWidth = "auto";

    return {
      borderRadius: borderRadius ? `${borderRadius}px` : "30px",
      width: selectedWidth || "unset",
      padding: padding || "5px 24px",
      minHeight: minHeight ? `${minHeight}px ` : "33px",
      color: color ?? "var(--color-purple)",
    };
  }
)<ButtonStyles>`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-family: var(--font-display);
  font-size: 13px;
  line-height: 22px;
  text-align: center;
  white-space: nowrap;
  box-sizing: border-box;
  border: 1px solid var(--color-purple);
  transition: all 0.33s ease-in-out;
  cursor: pointer;
  outline: none;
  background: var(--color-purple);
  color: white;
  ${({ borderRadius }) => css`
    border-radius: ${borderRadius};
  `}

  ${({ padding }) => css`
    padding: ${padding};
  `}

  ${({ minHeight }) => css`
    min-height: ${minHeight};
  `}

  ${({ width }) => css`
    width: ${width};
  `}

  &:hover {
    ${({ transparentHover }) => css`
      background: ${!transparentHover && "white"};
    `}

    ${({ transparentHover }) => css`
      color: ${!transparentHover && "var(--color-purple)"};
    `}

     ${({ transparentHover }) => css`
      border: ${!transparentHover && "1px solid var(--color-purple)"};
    `}

    .loader::after {
      border-color: var(--color-purple) transparent;
    }
  }

  &:disabled {
    cursor: not-allowed;
    color: white;
    background: #826799;

    &:hover {
      background: #826799;
      color: white;
    }
  }

  &.outlined {
    background: none;
    border: 1px solid var(--color-purple);

    ${({ color }) => css`
      color: ${color};
    `};

    &:hover {
      background: var(--color-purple);
      color: white;

      .loader::after {
        border-color: white transparent;
      }
    }

    .loader::after {
      border-color: var(--color-purple) transparent;
    }

    &:disabled {
      background: white;
      color: var(--color-purple);
      cursor: not-allowed;

      &:hover {
        background: var(--color-purple);
        color: white;

        .loader::after {
          border-color: white transparent;
        }
      }

      .loader::after {
        border-color: var(--color-purple) transparent;
      }
    }
  }

  &.textButton {
    background: none;
    border: none;
    ${({ color }) => css`
      color: ${color};
    `};

    &:hover {
      background: transparent;
    }

    .loader::after {
      border-color: var(--color-purple) transparent;
    }
  }
`;
