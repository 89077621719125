import styled from "styled-components";

import PageWrapper from "Components/Pages/Wrapper";

export const PageContainer = styled(PageWrapper)`
  padding-top: 20px;
  box-sizing: border-box;

  &.app-content .content-holder .page-heading {
    z-index: 0;
  }

  @media (min-width: 1260px) {
    &.app-content .content-holder {
      padding-top: 0;
      padding-bottom: 0px;
      height: 100%;
      overflow: hidden;
    }
  }
`;

export const PageContent = styled.div`
  flex: 1;
  height: 100%;
  display: grid;
  grid-template-rows: 132px 1fr;
  grid-template-columns: 1fr 391px;

  @media (min-width: 1600px) {
    grid-template-columns: 1fr 570px;
  }
`;

export const PageHeading = styled.div`
  grid-row: 1/2;
  grid-column: 1/2;
  width: auto;
`;

export const PageBody = styled.div`
  padding: 0;
  padding-right: 20px;
  margin-bottom: 20px;
  grid-row: 1/3;
  grid-column: 1/3;

  display: grid;
  grid-template-rows: 132px 1fr;
  grid-template-columns: 1fr 371px;

  .card-search-information {
    padding-right: 20px;
    padding-left: 20px;

    grid-column: 1/2;
    grid-row: 2/3;
    margin: 0;
  }
  .cart-information-resume {
    grid-column: 2/3;
    grid-row: 1/3;

    border-top: none;
    padding: 20px 20px;
    border-left: 1px solid #c4c4c4;
    position: unset;
    width: auto;
    padding-right: 0;
  }

  @media (min-width: 1600px) {
    grid-template-columns: 1fr 550px;
  }
`;
